import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import {
  MBLayout,
  MBAuth,
  MBSignIn,
  MBError404,
  MBError500,
} from "magicbean-saas-common";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: MBLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/sales-order/order-overview",
        name: "sales-order-order-overview",
        component: () => import("@/views/sales-order/order-overview/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/sales-order/order-overview/import",
        name: "sales-order-order-overview-import",
        component: () =>
          import("@/views/sales-order/order-overview/Import.vue"),
      },
      {
        path: "/sales-order/order-overview/:id",
        name: "sales-order-order-overview-detail",
        component: () =>
          import("@/views/sales-order/order-overview/Detail.vue"),
        children: [
          {
            path: "overview",
            name: "sales-order-order-overview-overview",
            component: () =>
              import("@/views/sales-order/order-overview/Overview.vue"),
          },
          {
            path: "sales-profilling",
            name: "sales-order-order-overview-sales-profilling",
            component: () =>
              import("@/views/sales-order/order-overview/SalesProfilling.vue"),
          },
          {
            path: "fulfillment-overview",
            name: "sales-order-order-overview-fulfillment-overview",
            component: () =>
              import(
                "@/views/sales-order/order-overview/FulfillmentOverview.vue"
              ),
          },
          {
            path: "order-tracking",
            name: "sales-order-order-overview-order-tracking",
            component: () =>
              import("@/views/sales-order/order-overview/OrderTracking.vue"),
          },
          {
            path: "exception-handlings",
            name: "sales-order-order-overview-exception-handlings",
            component: () =>
              import(
                "@/views/sales-order/order-overview/ExceptionHandlings.vue"
              ),
          },
          // {
          //   path: "support-tickets",
          //   name: "support-tickets",
          //   component: () =>
          //     import("@/views/sales-order/order-overview/SupportTickets.vue"),
          // },
        ],
      },

      {
        path: "/double-sale-order/order-overview",
        name: "double-sale-order-order-overview",
        component: () =>
          import("@/views/double-sale-order/order-overview/Index.vue"),
        meta: {
          keepAlive: true,
          contentWidth: "fluid",
        },
      },
      {
        path: "/double-sale-order/order-overview/import",
        name: "double-sale-order-order-overview-import",
        component: () =>
          import("@/views/double-sale-order/order-overview/Import.vue"),
      },
      {
        path: "/double-sale-order/order-overview/:id",
        component: () =>
          import("@/views/sales-order/order-overview/Detail.vue"),
        meta: {
          contentWidth: "fluid",
        },
        children: [
          {
            path: "overview",
            name: "double-sale-order-order-overview-overview",
            component: () =>
              import("@/views/sales-order/order-overview/Overview.vue"),
          },
          {
            path: "sales-profilling",
            name: "double-sale-order-order-overview-sales-profilling",
            component: () =>
              import("@/views/sales-order/order-overview/SalesProfilling.vue"),
          },
          {
            path: "fulfillment-overview",
            name: "double-sale-order-order-overview-fulfillment-overview",
            component: () =>
              import(
                "@/views/sales-order/order-overview/FulfillmentOverview.vue"
              ),
          },
          {
            path: "order-tracking",
            name: "double-sale-order-order-overview-order-tracking",
            component: () =>
              import("@/views/sales-order/order-overview/OrderTracking.vue"),
          },
          {
            path: "exception-handlings",
            name: "double-sale-order-order-overview-exception-handlings",
            component: () =>
              import(
                "@/views/sales-order/order-overview/ExceptionHandlings.vue"
              ),
          },
          // {
          //   path: "support-tickets",
          //   name: "double-sale-order-order-overview-support-tickets",
          //   component: () =>
          //     import("@/views/sales-order/order-overview/SupportTickets.vue"),
          // },
        ],
      },
      {
        path: "/double-sale-order/order-routing",
        name: "double-sale-order-order-routing",
        component: () =>
          import("@/views/double-sale-order/order-routing/Index.vue"),
        meta: {
          keepAlive: true,
          contentWidth: "fluid",
        },
      },
      {
        path: "/double-sale-order/draft-orders",
        name: "double-sale-order-draft-orders",
        component: () =>
          import("@/views/double-sale-order/draft-orders/Index.vue"),
        meta: {
          keepAlive: true,
          contentWidth: "fluid",
        },
      },
      {
        path: "/double-sale-order/draft-orders/:id",
        name: "double-sale-order-draft-order-detail",
        component: () =>
          import("@/views/double-sale-order/draft-orders/Detail.vue"),
        meta: {
          contentWidth: "fluid",
        },
        children: [
          {
            path: "overview",
            name: "double-sale-order-draft-order-overview",
            component: () =>
              import("@/views/double-sale-order/draft-orders/Overview.vue"),
            meta: {
              contentWidth: "fluid",
            },
          },
          {
            path: "sales-profilling",
            name: "double-sale-order-draft-order-sales-profilling",
            component: () =>
              import("@/views/sales-order/order-overview/SalesProfilling.vue"),
          },
        ],
      },
      {
        path: "/double-sale-order/shipment-label",
        name: "double-sale-order-shipment-label",
        component: () =>
          import("@/views/double-sale-order/shipment-label/Index.vue"),
        meta: {
          keepAlive: true,
          contentWidth: "fluid",
        },
      },
      {
        path: "/double-sale-order/shipment-label/:id",
        name: "double-sale-order-shipment-label-header-info",
        component: () =>
          import("@/views/double-sale-order/shipment-label/HeaderInfo.vue"),
        meta: {
          contentWidth: "fluid",
        },
        children: [
          {
            path: "list",
            name: "double-sale-order-shipment-label-list",
            component: () =>
              import(
                "@/views/double-sale-order/shipment-label/ShipmentLabelList.vue"
              ),
          },
          {
            path: "detail",
            name: "double-sale-order-shipment-label-detail",
            component: () =>
              import(
                "@/views//double-sale-order/shipment-label/ShipmentLabelDetail.vue"
              ),
          },
        ],
      },
      {
        path: "/sales-order/draft-orders",
        name: "sales-order-draft-orders",
        component: () => import("@/views/sales-order/draft-orders/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/sales-order/draft-orders/:id",
        name: "sales-order-draft-order-detail",
        component: () => import("@/views/sales-order/draft-orders/Detail.vue"),
        children: [
          {
            path: "overview",
            name: "sales-order-draft-order-overview",
            component: () =>
              import("@/views/sales-order/draft-orders/Overview.vue"),
          },
          {
            path: "sales-profilling",
            name: "sales-order-draft-order-sales-profilling",
            component: () =>
              import("@/views/sales-order/order-overview/SalesProfilling.vue"),
          },
        ],
      },
      {
        path: "/sales-order/order-verification",
        name: "sales-order-order-verification",
        component: () =>
          import("@/views/sales-order/order-verification/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/sales-order/order-verification/import",
        name: "sales-order-order-verification-import",
        component: () =>
          import("@/views/sales-order/order-verification/Import.vue"),
      },
      {
        path: "/sales-order/order-verification/:id",
        component: () =>
          import("@/views/sales-order/order-overview/Detail.vue"),
        children: [
          {
            path: "overview",
            name: "sales-order-order-verification-overview",
            component: () =>
              import("@/views/sales-order/order-overview/Overview.vue"),
          },
          {
            path: "sales-profilling",
            name: "sales-order-order-verification-sales-profilling",
            component: () =>
              import("@/views/sales-order/order-overview/SalesProfilling.vue"),
          },
          {
            path: "fulfillment-overview",
            name: "sales-order-order-verification-fulfillment-overview",
            component: () =>
              import(
                "@/views/sales-order/order-overview/FulfillmentOverview.vue"
              ),
          },
          {
            path: "order-tracking",
            name: "sales-order-order-verification-order-tracking",
            component: () =>
              import("@/views/sales-order/order-overview/OrderTracking.vue"),
          },
          {
            path: "exception-handlings",
            name: "sales-order-order-verification-exception-handlings",
            component: () =>
              import(
                "@/views/sales-order/order-overview/ExceptionHandlings.vue"
              ),
          },
          // {
          //   path: "support-tickets",
          //   name: "order-verification-support-tickets",
          //   component: () =>
          //     import("@/views/sales-order/order-overview/SupportTickets.vue"),
          // },
        ],
      },
      {
        path: "/merchant-order/order-overview",
        name: "merchant-order-order-overview",
        component: () =>
          import("@/views/merchant-order/order-overview/Index.vue"),
        meta: {
          keepAlive: true,
          contentWidth: "fluid",
        },
      },
      {
        path: "/merchant-order/order-overview/:id",
        component: () =>
          import("@/views/merchant-order/order-request/Detail.vue"),
        meta: {
          contentWidth: "fluid",
        },
        children: [
          {
            path: "add",
            name: "merchant-order-order-overview-add",
            component: () =>
              import("@/views/merchant-order/order-request/Overview.vue"),
          },
          {
            path: "overview",
            name: "merchant-order-order-overview-overview",
            component: () =>
              import("@/views/merchant-order/order-request/Overview.vue"),
          },
          // {
          //   path: "invoices",
          //   name: "merchant-order-order-overview-invoices",
          //   component: () =>
          //     import("@/views/merchant-order/order-request/Invoices.vue"),
          // },
          {
            path: "shipments",
            name: "merchant-order-order-overview-shipments",
            component: () =>
              import("@/views/merchant-order/order-request/Shipments.vue"),
          },
          {
            path: "logs",
            name: "merchant-order-order-overview-logs",
            component: () =>
              import("@/views/merchant-order/order-request/Logs.vue"),
          },
        ],
      },

      {
        path: "/merchant-order/order-request",
        name: "merchant-order-order-request",
        component: () =>
          import("@/views/merchant-order/order-request/Index.vue"),
        meta: {
          keepAlive: true,
          contentWidth: "fluid",
        },
      },
      {
        path: "/merchant-order/order-request/:id/information",
        name: "merchant-order-order-request-information",
        component: () =>
          import("@/views/merchant-order/order-request/Information.vue"),
      },
      {
        path: "/merchant-order/order-request/:id",
        component: () =>
          import("@/views/merchant-order/order-request/Detail.vue"),
        meta: {
          contentWidth: "fluid",
        },
        children: [
          {
            path: "add",
            name: "merchant-order-order-request-add",
            component: () =>
              import("@/views/merchant-order/order-request/Overview.vue"),
          },
          {
            path: "overview",
            name: "merchant-order-order-request-overview",
            component: () =>
              import("@/views/merchant-order/order-request/Overview.vue"),
          },
          // {
          //   path: "invoices",
          //   name: "merchant-order-order-request-invoices",
          //   component: () =>
          //     import("@/views/merchant-order/order-request/Invoices.vue"),
          // },
          {
            path: "shipments",
            name: "merchant-order-order-request-shipments",
            component: () =>
              import("@/views/merchant-order/order-request/Shipments.vue"),
          },
          {
            path: "logs",
            name: "merchant-order-order-request-logs",
            component: () =>
              import("@/views/merchant-order/order-request/Logs.vue"),
          },
        ],
      },

      {
        path: "/merchant-order/order-processing",
        name: "merchant-order-order-processing",
        component: () =>
          import("@/views/merchant-order/order-processing/Index.vue"),
        meta: {
          keepAlive: true,
          contentWidth: "fluid",
        },
      },
      {
        path: "/merchant-order/order-processing/:id/information",
        name: "merchant-order-order-processing-information",
        component: () =>
          import("@/views/merchant-order/order-request/Information.vue"),
        meta: {
          contentWidth: "fluid",
        },
      },
      {
        path: "/merchant-order/order-processing/:id",
        component: () =>
          import("@/views/merchant-order/order-request/Detail.vue"),
        meta: {
          contentWidth: "fluid",
        },
        children: [
          {
            path: "overview",
            name: "merchant-order-order-processing-overview",
            component: () =>
              import("@/views/merchant-order/order-request/Overview.vue"),
          },
          // {
          //   path: "invoices",
          //   name: "merchant-order-order-processing-invoices",
          //   component: () =>
          //     import("@/views/merchant-order/order-request/Invoices.vue"),
          // },
          {
            path: "shipments",
            name: "merchant-order-order-processing-shipments",
            component: () =>
              import("@/views/merchant-order/order-request/Shipments.vue"),
          },
          {
            path: "logs",
            name: "merchant-order-order-processing-logs",
            component: () =>
              import("@/views/merchant-order/order-request/Logs.vue"),
          },
        ],
      },

      {
        path: "/mb-order-routing/routing-overview",
        name: "mb-order-routing-routing-overview",
        component: () =>
          import("@/views/mb-order-routing/routing-overview/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/mb-order-routing/routing-overview/:id",
        component: () =>
          import("@/views/mb-order-routing/routing-overview/Detail.vue"),
        children: [
          {
            path: "overview",
            name: "order-assign-overview",
            component: () =>
              import("@/views/mb-order-routing/routing-overview/Overview.vue"),
          },
          {
            path: "refund-overview",
            name: "mb-order-routing-routing-overview-refund",
            component: () =>
                import("@/views/mb-order-routing/routing-overview/RefundOverview.vue"),
          },
          {
            path: "return-refund-overview",
            name: "mb-order-routing-routing-overview-return-refund",
            component: () =>
                import("@/views/mb-order-routing/routing-overview/RefundOverview.vue"),
          },
        ],
      },

      {
        path: "/mb-order-routing/fulfillment-orders",
        name: "mb-order-routing-fulfillment-orders",
        component: () =>
          import("@/views/mb-order-routing/fulfillment-orders/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/mb-order-routing/fulfillment-orders/:id/:fulfillment_order_number/",
        name: "mb-order-routing-fulfillment-order-detail",
        component: () =>
          import("@/views/mb-order-routing/fulfillment-orders/Detail.vue"),
        children: [
          {
            path: "",
            redirect: "mb-order-routing-fulfillment-order-overview",
          },
          {
            path: "overview",
            name: "mb-order-routing-fulfillment-order-overview",
            component: () =>
              import(
                "@/views/mb-order-routing/fulfillment-orders/FulfillmentOverview.vue"
              ),
          },
          {
            path: "shipment",
            name: "mb-order-routing-fulfillment-order-shipment",
            component: () =>
              import(
                "@/views/mb-order-routing/fulfillment-orders/Shipment.vue"
              ),
          },
          {
            path: "exceptions",
            name: "mb-order-routing-fulfillment-order-exceptions",
            component: () =>
              import(
                "@/views/mb-order-routing/fulfillment-orders/Exceptions.vue"
              ),
          },
        ],
      },

      {
        path: "/mb-order-routing/order-tracking",
        name: "mb-order-routing-order-tracking",
        component: () =>
          import("@/views/mb-order-routing/order-tracking/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/mb-order-routing/order-tracking/:id/:item_id/information",
        name: "mb-order-routing-order-tracking-information",
        component: () =>
          import("@/views/mb-order-routing/order-tracking/Information.vue"),
      },

      {
        path: "/risk-and-exception/exception-handling",
        name: "risk-and-exception-exception-handling",
        component: () =>
          import("@/views/risk-and-exception/exception-handling/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/risk-and-exception/exception-handling/:id/information",
        name: "risk-and-exception-exception-handling-information",
        component: () =>
          import(
            "@/views/risk-and-exception/exception-handling/ExceptionHandlingsInformation.vue"
          ),
      },

      {
        path: "/risk-and-exception/monitoring-rules",
        name: "risk-and-exception-monitoring-rules",
        component: () =>
          import("@/views/risk-and-exception/monitoring-rules/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/risk-and-exception/monitoring-rules/:id/information",
        name: "risk-and-exception-monitoring-rule-information",
        component: () =>
          import(
            "@/views/risk-and-exception/monitoring-rules/EditWarningRules.vue"
          ),
      },

      {
        path: "/documents/sales-invoice",
        name: "documents-sales-invoice",
        component: () => import("@/views/documents/sales-invoice/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/documents/credit-note",
        name: "documents-credit-note",
        component: () => import("@/views/documents/credit-note/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/documents/inspection-report",
        name: "documents-inspection-report",
        component: () =>
          import("@/views/documents/inspection-report/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/documents/inspection-report/:id/edit",
        name: "documents-inspection-report-information",
        component: () =>
          import(
            "@/views/documents/inspection-report/EditInspectionReport.vue"
          ),
      },
      {
        path: "/documents/proforma-invoice",
        name: "documents-proforma-invoice",
        component: () => import("@/views/documents/proforma-invoice/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/documents/delivery-note",
        name: "documents-delivery-note",
        component: () => import("@/views/documents/delivery-note/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/documents/packaging-list",
        name: "documents-packaging-list",
        component: () => import("@/views/documents/packaging-list/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/organisation-and-facility/warehouse",
        name: "organisation-and-facility-warehouse",
        component: () =>
          import("@/views/organisation-and-facility/warehouse/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/organisation-and-facility/warehouse/:id",
        name: "organisation-and-facility-warehouse-detail",
        component: () =>
          import("@/views/organisation-and-facility/warehouse/Detail.vue"),
        children: [
          {
            path: "",
            redirect: "organisation-and-facility-warehouse-information",
          },
          {
            path: "edit",
            name: "organisation-and-facility-warehouse-information",
            component: () =>
              import(
                "@/views/organisation-and-facility/warehouse/EditWarehouses.vue"
              ),
          },
          {
            path: "quality-inspection",
            name: "organisation-and-facility-warehouse-quality-inspection",
            component: () =>
              import(
                "@/views/organisation-and-facility/warehouse/EditQualityInspection.vue"
              ),
          },
        ],
      },
      {
        path: "/organisation-and-facility/logistics-service-provider",
        name: "organisation-and-facility-logistics-service-provider",
        component: () =>
          import(
            "@/views/organisation-and-facility/logistics-service-provider/Index.vue"
          ),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/organisation-and-facility/logistics-service-provider/:id/edit",
        name: "organisation-and-facility-logistics-service-provider-information",
        component: () =>
          import(
            "@/views/organisation-and-facility/logistics-service-provider/EditLogisticsServiceProvider.vue"
          ),
      },
      {
        path: "/transport-management/shipments",
        name: "transport-management-shipments",
        component: () =>
          import("@/views/transport-management/shipments/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/transport-management/shipments/:id/:shipment_id",
        name: "transport-management-shipment-detail",
        component: () =>
          import("@/views/transport-management/shipments/Detail.vue"),
        children: [
          {
            path: "",
            redirect: "transport-management-shipment-information",
          },
          {
            path: "edit",
            name: "transport-management-shipment-information",
            component: () =>
              import("@/views/transport-management/shipments/EditShipment.vue"),
          },
          {
            path: "logs",
            name: "transport-management-shipment-log",
            component: () =>
              import("@/views/transport-management/shipments/Logs.vue"),
          },
        ],
      },
      {
        path: "/declaration-management/export-declaration",
        name: "declaration-management-export-declaration",
        component: () =>
          import("@/views/declaration-management/export-declaration/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      // {
      //   path: "/declaration-management/export-declaration/:id/edit",
      //   name: "declaration-management-export-declaration-information",
      //   component: () =>
      //     import("@/views/declaration-management/export-declaration/EditExportDeclaration.vue"),
      // },
      {
        path: "/declaration-management/export-declaration/:id",
        name: "declaration-management-export-declaration-detail",
        component: () =>
          import(
            "@/views/declaration-management/export-declaration/Detail.vue"
          ),
        children: [
          {
            path: "",
            redirect: "declaration-management-export-declaration-information",
          },
          {
            path: "edit",
            name: "declaration-management-export-declaration-information",
            component: () =>
              import(
                "@/views/declaration-management/export-declaration/EditExportDeclaration.vue"
              ),
          },
          {
            path: "logs",
            name: "declaration-management-export-declaration-log",
            component: () =>
              import(
                "@/views/declaration-management/export-declaration/Logs.vue"
              ),
          },
        ],
      },
      {
        path: "/declaration-management/import-declaration",
        name: "declaration-management-import-declaration",
        component: () =>
          import("@/views/declaration-management/import-declaration/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/declaration-management/import-declaration/:id/edit",
        name: "declaration-management-import-declaration-information",
        component: () =>
          import(
            "@/views/declaration-management/import-declaration/EditImportDeclaration.vue"
          ),
      },
      {
        path: "/warehouse-management/inbound",
        name: "warehouse-management-inbound",
        component: () =>
          import("@/views/warehouse-management/inbound/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/warehouse-management/inspection",
        name: "warehouse-management-inspection",
        component: () =>
          import("@/views/warehouse-management/inspection/Index.vue"),
        meta: {
          keepAlive: true,
          contentWidth: "fluid",
        },
      },
      {
        path: "/warehouse-management/inspection/:id",
        name: "warehouse-management-inspection-detail",
        component: () =>
          import("@/views/warehouse-management/inspection/Detail.vue"),
        meta: {
          contentWidth: "fluid",
        },
        children: [
          {
            path: "overview",
            name: "warehouse-management-inspection-overview",
            component: () =>
              import("@/views/warehouse-management/inspection/Overview.vue"),
          },
          {
            path: "inspections",
            name: "warehouse-management-inspection-inspections",
            component: () =>
              import("@/views/warehouse-management/inspection/Inspections.vue"),
          },
          {
            path: "logs",
            name: "warehouse-management-inspection-logs",
            component: () =>
              import("@/views/warehouse-management/inspection/Logs.vue"),
          },
        ],
      },
      {
        path: "/warehouse-management/warehouse-inventory",
        name: "warehouse-management-warehouse-inventory",
        component: () =>
          import("@/views/warehouse-management/warehouse-inventory/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/warehouse-management/stock-in",
        name: "warehouse-management-stock-in",
        component: () =>
          import("@/views/warehouse-management/stock-in/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/warehouse-management/stock-in/:id/:stock_in_id/edit",
        name: "warehouse-management-stock-in-information",
        component: () =>
          import("@/views/warehouse-management/stock-in/EditStockIn.vue"),
      },
      {
        path: "/warehouse-management/stock-out",
        name: "warehouse-management-stock-out",
        component: () =>
          import("@/views/warehouse-management/stock-out/Index.vue"),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/warehouse-management/stock-out/:id/edit",
        name: "warehouse-management-stock-out-information",
        component: () =>
          import("@/views/warehouse-management/stock-out/EditStockOut.vue"),
      },
      // {
      //   path: "/tms/inventory",
      //   name: "tms-inventory",
      //   component: () => import("@/views/tms/inventory/Index.vue"),
      //   meta: {
      //     keepAlive: true,
      //   },
      // },
      // {
      //   path: "/tms/inventory/:id/edit",
      //   name: "exception-tms-inventory-information",
      //   component: () => import("@/views/tms/inventory/EditInventory.vue"),
      // },
    ],
  },
  {
    path: "/",
    component: MBAuth,
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: MBSignIn,
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: MBError404,
  },
  {
    path: "/500",
    name: "500",
    component: MBError500,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  // scrollBehavior: (to, from, savedPosition) => {
  //   if (store.getters.isMobile) {
  //     history.pushState(null, "", document.URL);
  //   }
  // },
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (to.meta.contentWidth == "fluid") {
    store.commit(Mutations.OVERRIDE_PAGE_LAYOUT_CONFIG, {
      content: {
        width: "fluid",
      },
    });
  }

  if (to.name !== "sign-in") {
    store.dispatch(Actions.VERIFY_AUTH, () => {
      store.dispatch(Actions.GET_MENU_ITEMS, {
        path: to.path,
        callback: () => {
          next();
        },
      });
    });
  } else {
    next();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
