/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-12-01 17:19:16
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-12-05 10:24:10
 */
import menu from "magicbean-saas-common/src/core/language/zh/menu.json";
import common from "./common.json";
import salesOrder from "./salesOrder.json";
import salesOrderOverview from "./salesOrderOverview.json";
import exceptionHandlings from "./exceptionHandlings.json";
import fulfillmentOrder from "./fulfillmentOrder.json";
import order from "./order.json";
import warningRules from "./warningRules.json";
import salesInvoice from "./salesInvoice.json";
import shipments from "./shipments.json";
import stockIn from "./stockIn.json";
import stockOut from "./stockOut.json";
import creditNote from "./creditNote.json";
import inspectionReport from "./inspectionReport.json";
import logisticsServiceProvider from "./logisticsServiceProvider.json";
import inventory from "./inventory.json";
import warehouse from "./warehouse.json";
import customDeclarationExport from "./customDeclarationExport.json";
import proformaInvoice from "./proformaInvoice.json";
import orderTracking from "./orderTracking.json";
import customDeclaration from "./customDeclaration.json";
import orderRequest from "./orderRequest.json";
import orderAssign from "./orderAssign.json";
import inbound from "./inbound.json";
import inspection from "./inspection.json";
import warehouseInventory from "./warehouseInventory.json";
import draftOrder from "./draftOrder.json";
import shipmentLabel from "./shipmentLabel.json";
import orderRefund from "./orderRefund.json";

export default {
  menu,
  common,
  salesOrder,
  salesOrderOverview,
  exceptionHandlings,
  fulfillmentOrder,
  order,
  warningRules,
  salesInvoice,
  shipments,
  stockIn,
  stockOut,
  creditNote,
  inspectionReport,
  logisticsServiceProvider,
  inventory,
  warehouse,
  customDeclaration,
  customDeclarationExport,
  proformaInvoice,
  orderTracking,
  orderRequest,
  orderAssign,
  inbound,
  inspection,
  warehouseInventory,
  draftOrder,
  shipmentLabel,
  orderRefund,
};
